import React from "react";

import styled from "styled-components";

import AppLogo from "../appLogo/AppLogo";
import NavButtons from "./NavButtons";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.84);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 50px; */
  height: 60px;
  z-index: 100;
`;

const Logo = styled(AppLogo)`
  width: 240px;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  & > img {
    left: -80px;
    top: -172px;
    transform: scale(0.75);
    transform-origin: center;
  }
`;

const NavBar = ({ onClick, scrollIndex }) => {
  return (
    <Container>
      <Logo onClick={() => onClick(0)} />
      <NavButtons onClick={onClick} scrollIndex={scrollIndex} />
    </Container>
  );
};
export default NavBar;
