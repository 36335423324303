import React from "react";

import { isDesktop } from "../../util/formFactor";
import NavButtonsDesktop from "./NavButtonsDesktop";
import NavButtonsPhone from "./NavButtonsPhone";

const NavButtons = ({ onClick, scrollIndex }) => {
  function showEmail() {
    window.open("mailto:claire@clkconsulting.org?subject=Re: CLK Consulting");
  }

  return isDesktop() ? (
    <NavButtonsDesktop
      onClick={onClick}
      scrollIndex={scrollIndex}
      showEmail={showEmail}
    />
  ) : (
    <NavButtonsPhone
      onClick={onClick}
      scrollIndex={scrollIndex}
      showEmail={showEmail}
    />
  );
};
export default NavButtons;
