import React from "react";

import styled from "styled-components";

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const Button = styled.div`
  padding: 0 15px;
  cursor: pointer;
  color: ${({ isSelected }: { isSelected: boolean }) =>
    !!isSelected ? "rgb(130,130,130) !important" : "black"}
  &:hover {
    color: rgb(100, 100, 100);
  }
`;

const NavButtonsDesktop = ({ onClick, scrollIndex, showEmail }) => (
  <ButtonBox>
    <Button onClick={() => onClick(1)} isSelected={scrollIndex === 1}>
      About
    </Button>
    <Button onClick={() => onClick(2)} isSelected={scrollIndex === 2}>
      Mission
    </Button>
    <Button onClick={() => onClick(3)} isSelected={scrollIndex === 3}>
      What we do
    </Button>
    {/* <Button onClick={() => onClick(4)} isSelected={scrollIndex === 4}>
  Clients
</Button> */}
    <Button onClick={showEmail} isSelected={scrollIndex === 4}>
      Contact
    </Button>
  </ButtonBox>
);
export default NavButtonsDesktop;
