import React from "react";

import styled from "styled-components";

import logo from "../../assets/Logo.svg";

const LogoBox = styled.div`
  width: 400px;
  height: 100px;
  /* overflow: hidden; */
  & > img {
    position: relative;
    top: -125px;
  }
`;

const Logo = styled.img`
  width: 400px;
`;

const AppLogo = ({ onClick, className }) => {
  function handleClick() {
    if (onClick) {
      onClick();
    }
  }
  return (
    <LogoBox onClick={handleClick} className={className}>
      <Logo src={logo} fill="red" />
    </LogoBox>
  );
};
export default AppLogo;
