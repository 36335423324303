const DESKTOP: string = "desktop";
const MOBILE: string = "mobile";
const PHONE: string = "phone";
const IPHONE: string = "iphone";
const IPOD: string = "ipod";
const IPAD: string = "ipad";
const TABLET: string = "tablet";
const ANDROID: string = "android";

export const FormFactor = {
  DESKTOP,
  PHONE,
  TABLET
};

let formFactor: string;

/**
 * Sets the form factor if not present and returns the result.
 */
export const getFormFactor = (): string => {
  if (!window) {
    formFactor = "desktop";
    return formFactor;
  }
  if (!formFactor) {
    // Detect form factor from user agent.
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.includes(IPHONE) || ua.includes(IPOD)) {
      formFactor = PHONE;
    } else if (ua.includes(IPAD)) {
      formFactor = TABLET;
    } else if (ua.includes(ANDROID) || ua.includes(MOBILE)) {
      const dpi = 160;
      const width = window.screen.width / dpi;
      const height = window.screen.height / dpi;
      const size = Math.sqrt(width * width + height * height);
      if (size < 6) {
        formFactor = PHONE;
      } else {
        formFactor = TABLET;
      }
    } else {
      formFactor = DESKTOP;
    }
  }
  return formFactor;
};

export const isPhone = (): boolean => PHONE === getFormFactor();

export const isTablet = (): boolean => TABLET === getFormFactor();

export const isMobile = (): boolean => isPhone() || isTablet();

export const isDesktop = (): boolean => DESKTOP === getFormFactor();
