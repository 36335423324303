import React from "react";

import styled from "styled-components";

import PageSection from "../PageSection/PageSection";
import StyledText, { StyledTextBox } from "../styledComponents/StyledText";

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  padding: 40px 0px;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 40px;
  letter-spacing: 1px;
`;

const AboutPage = ({ scrollIndex, onEnter, pageIndex }) => {
  return (
    <PageSection
      isSelected={scrollIndex === pageIndex}
      index={pageIndex}
      onEnter={onEnter}
    >
      <Container>
        {/* <ImgBox>
          <Img
            src="https://images.unsplash.com/photo-1533258439784-28006397342d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80"
            alt="about"
          />
        </ImgBox> */}
        <StyledTextBox>
          <Title>ABOUT THE FOUNDER</Title>
          <StyledText>
            Over the last 16 years, I have worked as a senior buyer and sourcing
            manager for some of the UK’s leading retailers. I have always been
            passionate about product and delivering the best value and quality
            within an incredibly fast-paced marketplace.
          </StyledText>
          <StyledText>
            While travelling the world shopping for inspiration, trawling
            through vintage racks and seeing street style up close in some of
            the most trend-led cities in the world, I observed a real growth in
            awareness from the fashion industry of its damaging impact on the
            planet.
          </StyledText>
          <StyledText>
            I have worked with all levels of manufacturing categories globally
            and through this was able to focus on the importance of knowing
            where your product is coming from in order to develop a transparent
            supply chain that protects both workers and the environment.
          </StyledText>
          <StyledText>
            This led me into the world of sourcing, working with a hugely
            successful e-commerce retailer who has seen extensive growth and is
            still growing sales globally. Somewhat uniquely for such a big
            retailer, they have increased their sustainable story while ensuring
            product is sourced from an ethical supply base.
          </StyledText>
          <StyledText>
            My personal focus has been on the impact we have been making on the
            planet and how we can develop and train teams to buy smarter, ask
            questions and expect transparency.
          </StyledText>
          <StyledText>
            Transparency and the conversation between brands and manufacturers
            is essential to moving our industry into a greener more sustainable
            future and this is what drives me in my work with CLK Consulting.
          </StyledText>
        </StyledTextBox>
      </Container>
    </PageSection>
  );
};
export default AboutPage;
