import React from "react";

import styled from "styled-components";

import PageSection from "../PageSection/PageSection";
import StyledPageTitle from "../styledComponents/StyledPageTitle";
import StyledText, { StyledTextBox } from "../styledComponents/StyledText";

const Important = styled(StyledText)`
  font-size: 28px;
`;

const Title = styled(StyledPageTitle)`
  padding-top: 60px;
  font-size: 42px;
`;

const MissionPage = ({ scrollIndex, pageIndex, onEnter }) => {
  return (
    <PageSection
      isSelected={scrollIndex === pageIndex}
      index={pageIndex}
      onEnter={onEnter}
    >
      <Title>OUR MISSION</Title>
      <StyledTextBox>
        <Important>
          Inspire companies to grow their brands in a sustainable direction
          without compromising on product integrity.
        </Important>
      </StyledTextBox>
    </PageSection>
  );
};
export default MissionPage;
