import React, { useRef, useState } from "react";
import { Collapse } from "react-collapse";
import { MdMenu } from "react-icons/md";

import styled from "styled-components";

import useClickOutside from "../../util/useClickOutside";

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const MenuButton = styled.div`
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropDown = styled.div`
  width: 100vw;
  position: absolute;
  top: 60px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const Menu = styled(MdMenu)`
  font-size: 29px;
`;

const Button = styled.div`
  padding: 15px 0;
  width: 100%;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: ${({ isSelected }: { isSelected: boolean }) =>
    !!isSelected ? "rgb(130,130,130) !important" : "rgb(40,40,40)"};
  user-select: none;
  &:hover {
    color: rgb(100, 100, 100);
  }
  &: active {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const NavButtonsPhone = ({ onClick, scrollIndex, showEmail }) => {
  const dropDownRef = useRef(null);
  const [open, setOpen] = useState(false);
  useClickOutside(dropDownRef, handleClickOutside);

  function handleClickOutside() {
    setOpen(false);
  }

  function handleClick(index: number) {
    setOpen(false);
    if (onClick) {
      onClick(index);
    }
  }

  function handleShowEmail() {
    setOpen(false);
    if (showEmail) {
      showEmail();
    }
  }

  return (
    <Container>
      <MenuButton onClick={() => setOpen(s => !s)}>
        <Menu />
      </MenuButton>
      <DropDown ref={dropDownRef}>
        <Collapse
          isOpened={open}
          initialStyle={{ height: "0px", overflow: "hidden" }}
        >
          <Button onClick={() => handleClick(1)} isSelected={scrollIndex === 1}>
            About
          </Button>
          <Button onClick={() => handleClick(2)} isSelected={scrollIndex === 2}>
            Mission
          </Button>
          <Button onClick={() => handleClick(3)} isSelected={scrollIndex === 3}>
            What we do
          </Button>
          {/* <Button onClick={() => onClick(4)} isSelected={scrollIndex === 4}>
  Clients
</Button> */}
          <Button onClick={handleShowEmail} isSelected={scrollIndex === 4}>
            Contact
          </Button>
        </Collapse>
      </DropDown>
    </Container>
  );
};
export default NavButtonsPhone;
