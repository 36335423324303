import React from "react";

import AboutPage from "../AboutPage/AboutPage";
import DoPage from "../DoPage/DoPage";
import HeroPage from "../HeroPage/HeroPage";
import MissionPage from "../MissionPage/MissionPage";

const VerticalScroller = ({
  scrollIndex,
  isScrolling,
  setScrollIndex,
  onClickDot
}) => (
  <div>
    <HeroPage pageIndex={0} scrollIndex={scrollIndex} />
    <AboutPage pageIndex={1} scrollIndex={scrollIndex} />
    <MissionPage pageIndex={2} scrollIndex={scrollIndex} />
    <DoPage pageIndex={3} scrollIndex={scrollIndex} />
    {/* <ClientsPage pageIndex={4} scrollIndex={scrollIndex} /> */}
    {/* <PageDots
        dots={[{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]}
        dotIndex={scrollIndex}
        onClick={onClickDot}
      /> */}
  </div>
);
export default VerticalScroller;
