import React from "react";

import styled from "styled-components";

import AppLogo from "../appLogo/AppLogo";
import PageSection from "../PageSection/PageSection";

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const CaptionBox = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  /* margin-top: 70px; */
  line-height: 30px;
  letter-spacing: 1px;
  max-width: 400px;
  padding: 0 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

// const CaptionTitle = styled.div`
//   font-size: 35px !important;
//   font-weight: 800;
//   margin-bottom: 20px;
//   letter-spacing: 3px;
//   line-height: 37px;
// `;

// const HeroBox = styled.div`
//   position: absolute;
//   background-color: yellow;
//   left: -10px;
//   top: -10px;
//   width: 110%;
//   height: 110%;
//   z-index: 0;
//   background: url("https://images.unsplash.com/photo-1487700160041-babef9c3cb55?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1635&q=80")
//     no-repeat;
//   background-size: cover;
//   filter: blur(8px) brightness(0.8);
// `;

const HeroPage = ({ scrollIndex, pageIndex }) => {
  return (
    <PageSection isSelected={scrollIndex === pageIndex} index={pageIndex}>
      {/* <HeroBox></HeroBox> */}
      {/* <HeroFader /> */}
      <TextBox>
        <AppLogo />
        <CaptionBox>
          {/* <CaptionTitle>SUSTAINABILITY EXPERTS</CaptionTitle> */}
          <div>
            Helping fashion businesses to find a more sustainable future
          </div>
        </CaptionBox>
      </TextBox>
    </PageSection>
  );
};
export default HeroPage;
