import React, { useState } from "react";

import NavBar from "./components/NavBar/NavBar";
import VerticalScroller from "./components/VerticalScroller/VerticalScroller";

import "./App.css";

function App() {
  const [scrollIndex, setScrollIndex] = useState(0);

  function onClickDot(index) {
    // forces the scroll even if the index was not changed.
    setScrollIndex(-1);
    setTimeout(() => {
      setScrollIndex(index);
    });
  }
  return (
    <div className="App">
      <VerticalScroller
        scrollIndex={scrollIndex}
        setScrollIndex={setScrollIndex}
        onClickDot={onClickDot}
      />
      <NavBar onClick={onClickDot} scrollIndex={scrollIndex} />
    </div>
  );
}

export default App;
