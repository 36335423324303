import React, { useEffect, useRef } from "react";

import styled from "styled-components";

const Page = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${({ index }: { index: number }) =>
    index % 2 !== 0 ? "white" : "rgba(0,0,0,0.05)"};
`;

// const THRESHOLD = [0.5];

const PageSection = ({ children, isSelected, index /*, onEnter*/ }) => {
  // const [ref, inView, entry] = useInView({ threshold: THRESHOLD });
  const scrollRef = useRef(null);

  useEffect(() => {
    if (isSelected && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [isSelected, scrollRef]);

  return (
    <div ref={scrollRef}>
      <Page index={index}>{children}</Page>
    </div>
  );
};
export default PageSection;
