import { useEffect } from "react";

const createHandler = (containerRef, callback) => {
  return e => {
    if (containerRef && containerRef.current.contains(e.target)) {
      return;
    }
    callback();
  };
};

const useClickOutside = (containerRef, handleClickOutside) => {
  const ref = containerRef && containerRef.current;
  useEffect(() => {
    if (!ref) {
      return;
    }
    const handler = createHandler(containerRef, handleClickOutside);
    document.addEventListener("mousedown", handler, false);
    return () => {
      document.removeEventListener("mousedown", handler, false);
    };
  }, [containerRef, handleClickOutside, ref]);
};
export default useClickOutside;
