import styled from "styled-components";

const StyledPageTitle = styled.div`
  width: 100%;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
export default StyledPageTitle;
