import React from "react";
import styled from "styled-components";
import PageSection from "../PageSection/PageSection";
import StyledPageTitle from "../styledComponents/StyledPageTitle";
import StyledText, { StyledTextBox } from "../styledComponents/StyledText";

const Container = styled.div`
  /* height: 100%; */
  padding: 100px 0;
`;

const DoPage = ({ scrollIndex, pageIndex, onEnter }) => {
  return (
    <PageSection
      isSelected={scrollIndex === pageIndex}
      index={pageIndex}
      onEnter={onEnter}
    >
      <Container>
        <StyledPageTitle>WHAT WE DO</StyledPageTitle>
        <StyledTextBox>
          <StyledText>
            CLK Consulting supports businesses to plan strategic sourcing
            strategies that drive product, trend and quality with a reduced
            impact on the earth.
          </StyledText>
          <StyledText>
            We explain, train and help brands understand the need for
            transparency in order to grow partnerships and improve working
            relationships in order to deliver ethically-sourced sustainable
            product without compromising profitability.
          </StyledText>
          <StyledText>
            Drawing on our knowledge and experience gained from working in
            manufacturing and production across multiple regions worldwide, we
            can advise and guide our clients through planning, production and
            route to market using the most ethical and sustainable approach to
            do so.
          </StyledText>
          <StyledText>
            We will also work with companies to advise them on ways they can
            support communities in key areas of manufacturing to improve quality
            of life, wealth and health in underdeveloped regions.
          </StyledText>
          <StyledText>
            Working towards a future of workers feeling empowered and able to
            work in healthy and safe conditions is vital for the continued
            growth of the fashion industry. We want to be part of an
            industry-wide change for the future.
          </StyledText>
        </StyledTextBox>
      </Container>
    </PageSection>
  );
};
export default DoPage;
