import styled from "styled-components";

export const StyledTextBox = styled.div`
  padding: 40px;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  /* max-width: 50%; */
  max-width: 980px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledText = styled.p`
  font-size: 18px;
  font-weight: 400;
  max-width: 90%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
export default StyledText;
